import { ErrorInfo } from 'react'
import App, { AppProps } from 'next/app'
import * as Sentry from '@sentry/node'
import { Options } from '@sentry/types/dist/options'
import { RewriteFrames } from '@sentry/integrations'
import { StackFrame } from '@sentry/types'
import NextNprogress from 'nextjs-progressbar'
import 'styles/tailwind.css'
import 'styles/global.css'

function framesModified(frame: StackFrame) {
  if (frame.filename?.startsWith('/opt')) {
    const base = frame.filename.replace(/\/opt\/app\/\.next/, '')
    frame.filename = base
  } else if (frame.filename) {
    const base = frame.filename.replace(/\/_next/, '')
    frame.filename = base
  }
  return frame
}
const sentryOptions: Options = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,
  attachStacktrace: true,
  release: process.env.RELEASE,
  integrations: [
    new RewriteFrames({
      iteratee: framesModified,
    }),
  ],
}

if (process.env.APP_ENV !== 'production') {
  sentryOptions.beforeSend = event => {
    console.group(event.fingerprint?.join())
    console.error(event)
    console.groupEnd()
    return null
  }
}

Sentry.init(sentryOptions)

class AppliftingApp extends App {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key as keyof ErrorInfo])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    const { Component, pageProps }: AppProps = this.props
    return (
      <>
        <NextNprogress
          color="#000000"
          height={1}
          startPosition={0.3}
          stopDelayMs={200}
          options={{ easing: 'ease', speed: 100, showSpinner: false }}
        />
        <Component {...pageProps} />
      </>
    )
  }
}
export default AppliftingApp
